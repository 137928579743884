<template>
    <div class="whatsapp-float flex justify-center items-center ">
      <a href="#" @click.prevent="openWhatsApp()" class="">
        <font-awesome-icon class="whatsapp-icon rounded-circle mx-3" icon="fa-brands fa-whatsapp" />
         <span class="whatsapp-label  ">Let's Chat</span>
      </a>
    </div>
  </template>
  <script>
  export default {
    methods: {
      openWhatsApp() {
       
        const url = `https://api.whatsapp.com/send?phone=254743417198&text=Hello%20Igiza%20Arts%20I%20need%20your%20services`;
        window.open(url, '_blank');
      },
    },  
  };
  </script>
  
  
  <style scoped>
  
  a{
       text-decoration: none;
  
  }

  .debugger{
    border:2px solid green;
  }
  
  .whatsapp-float {
    position: fixed;
    bottom: 90px;
    right: 20px;
    z-index: 100;
  }
  
  .whatsapp-icon {
    font-size: 35px;
    color: #25d366;
    
  }
  
  .whatsapp-label {
    display: block;
    font-size: 15px;
    color: #25d366;
    text-align: center;
    margin-top: 5px;
    font-weight: bold;
   
  }
  
  </style>