<template>
  <section class="services-section py-5 bg-light" id="services">
    <div class="container">
      <h2 class="section-title text-center mb-5" data-aos="fade-up">What We Do</h2>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        <div class="col" v-for="(service, index) in services" :key="index" 
             data-aos="fade-up" 
             :data-aos-delay="index * 100">
          <div class="service-card h-100">
            <div class="card-icon mb-3">
              <font-awesome-icon :icon="service.icon" />
            </div>
            <h3 class="card-title">{{ service.title }}</h3>
            <p class="card-text">{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ServicesSection',
  props: {
    services: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.services-section {
  background-color: #f8f9fa;
  padding: 100px 0;
}

.section-title {
  font-weight: 700;
  color: #333;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
}

.service-card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.card-icon {
  font-size: 2.5rem;
  color: #ff4e50;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.service-card:hover .card-icon {
  transform: scale(1.2);
}

.card-title {
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.card-text {
  color: #666;
  line-height: 1.6;
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .services-section {
    padding: 80px 0;
  }
}

@media (max-width: 767px) {
  .services-section {
    padding: 60px 0;
  }
  
  .section-title {
    font-size: 1.8rem;
  }
  
  .service-card {
    padding: 20px;
  }
  
  .card-icon {
    font-size: 2rem;
  }
}
</style>