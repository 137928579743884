<template>
  <!--Toast Alert Component is imported here-->
  <ToastNotifications ref="toastNotificationsRef" />
  <footer class="footer-section">
    <!-- Main Footer -->
    <div class="main-footer py-5">
      <div class="container">
        <div class="row">
          <!-- Company Info -->
          <div class="col-lg-4 mb-4 mb-lg-0">
            <div class="footer-brand mb-4">
              <img
                src="../assets/images/igizalogo.png"
                alt="Igiza Arts Production"
                class="footer-logo"
              />
            </div>
            <p class="footer-text">
              A passionate theatre production company dedicated to bringing
              captivating stories to life on stage. With a commitment to
              creativity, innovation, and excellence.
            </p>
            <div class="social-links mt-4">
              <a
                href="https://www.youtube.com/@IgizaArtsProduction"
                target="_blank"
                class="social-link"
              >
                <font-awesome-icon :icon="['fab', 'youtube']" />
              </a>
              <a
                href="https://www.instagram.com/igiza_arts/"
                target="_blank"
                class="social-link"
              >
                <font-awesome-icon :icon="['fab', 'instagram']" />
              </a>

              <a
                href="https://www.facebook.com/IgizaArtsProduction"
                target="_blank"
                class="social-link"
              >
                <font-awesome-icon :icon="['fab', 'facebook']" />
              </a>
              <a
                href="https://www.linkedin.com/company/igiza-arts-production/"
                target="_blank"
                class="social-link"
              >
                <font-awesome-icon :icon="['fab', 'linkedin']" />
              </a>

              <a
                href="https://x.com/igiza_arts"
                target="_blank"
                class="social-link"
              >
                <font-awesome-icon :icon="['fab', 'twitter']" />
              </a>
            </div>
          </div>

          <!-- Quick Links -->
          <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
            <h5 class="footer-heading">Quick Links</h5>
            <ul class="footer-links">
              <li><a href="#" @click.prevent="scrollToTop">Home</a></li>
              <li><a href="#about">About Us</a></li>
              <li><a href="#events">Events</a></li>
              <li><a href="#gallery">Gallery</a></li>
              <li><a href="#playbill">Playbill</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>

          <!-- Our Services -->
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 class="footer-heading">Our Services</h5>
            <ul class="footer-links">
              <li><a href="#">Stage Productions</a></li>
              <li><a href="#events">Event Productions</a></li>
              <li><a href="#about">Igiza Academy</a></li>
              <li><a href="#about">Community Engagement</a></li>
              <li><a href="#contact">Scriptwriting & Directing</a></li>
              <li><a href="#contact">Theatre Consultancy</a></li>
            </ul>
          </div>

          <!-- Contact Info -->
          <div class="col-lg-3">
            <h5 class="footer-heading">Contact Info</h5>
            <ul class="footer-contact">
              <li>
                <font-awesome-icon
                  icon="fa-solid fa-location-dot"
                  class="contact-icon"
                />
                <span>Kenya National Theatre, Nairobi</span>
              </li>
              <li>
                <font-awesome-icon
                  icon="fa-solid fa-phone"
                  class="contact-icon"
                />
                <span>+254 743 417198</span>
              </li>
              <li>
                <font-awesome-icon
                  icon="fa-solid fa-envelope"
                  class="contact-icon"
                />
                <span>igizaarts@gmail.com</span>
              </li>
            </ul>

            <!-- Newsletter Subscribe -->
            <div class="newsletter-container mt-4">
              <h6 class="newsletter-title">Subscribe to our newsletter</h6>
              <div class="newsletter-form">
                <div class="input-group">
                  <input
                    type="email"
                    class="form-control text-white"
                    placeholder="Your Email"
                    v-model="newsletterEmail"
                  />
                  <button
                    class="btn btn-subscribe"
                    type="button"
                    @click="subscribeNewsletter"
                  >
                    <font-awesome-icon icon="fa-solid fa-paper-plane" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Copyright -->
    <div class="copyright-bar py-3">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <p class="copyright-text mb-md-0">
              &copy; {{ new Date().getFullYear() }} Igiza Arts Production. All
              rights reserved.
            </p>
          </div>
          <div class="col-md-6 text-md-end">
            <div class="footer-bottom-links">
              <router-link to="/privacy-policy">Privacy Policy</router-link> |
              <a
                href="https://www.volcha.co.ke"
                target="_blank"
                rel="noopener noreferrer"
              >
                Designed & Built by Volcha
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Back to Top Button -->
    <button
      class="back-to-top"
      :class="{ active: showBackToTop }"
      @click="scrollToTop"
    >
      <font-awesome-icon icon="fa-solid fa-arrow-up" />
    </button>
  </footer>
</template>

<script>
import axios from "axios";
import ToastNotifications from "@/components/ToastNotifications.vue";
export default {
  name: "FooterSection",
  components: { ToastNotifications },
  data() {
    return {
      newsletterEmail: "",
      showBackToTop: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async subscribeNewsletter() {
      if (!this.newsletterEmail) {
        this.errorToast("Please enter a valid email address");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}subscribe`,
          { email: this.newsletterEmail }
        );

        this.successToast(response.data.message);
        this.newsletterEmail = ""; // Reset input field
      } catch (error) {
        this.errorToast(error.response.data.error);
      }
    },
    handleScroll() {
      this.showBackToTop = window.scrollY > 500;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    errorToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },

    successToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("success", message);
    },
  },
};
</script>

<style scoped>
.footer-section {
  position: relative;
}

.main-footer {
  background-color: #1a1a1a;
  color: rgba(255, 255, 255, 0.7);
}

.footer-logo {
  height: 60px;
  margin-bottom: 1rem;
}

.footer-text {
  line-height: 1.8;
  max-width: 300px;
}

.footer-heading {
  color: white;
  font-weight: 700;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 10px;
}

.footer-heading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 0.8rem;
}

.footer-links a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
}

.footer-links a:hover {
  color: #ff4e50;
  transform: translateX(5px);
}

.footer-contact {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-contact li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.7);
}

.contact-icon {
  color: #ff4e50;
  margin-right: 10px;
  margin-top: 5px;
}

.social-links {
  display: flex;
  gap: 15px;
}

.social-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.social-link:hover {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  transform: translateY(-3px);
}

.newsletter-title {
  color: white;
  font-weight: 600;
  margin-bottom: 10px;
}

.input-group {
  border-radius: 30px;
  overflow: hidden;
}

.form-control {
  border: none;
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  padding: 12px 15px;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.form-control:focus {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-subscribe {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border: none;
  color: white;
  padding: 0 20px;
  transition: all 0.3s ease;
}

.btn-subscribe:hover {
  background: linear-gradient(45deg, #f9d423, #ff4e50);
}

.copyright-bar {
  background-color: #111;
  color: rgba(255, 255, 255, 0.6);
}

.copyright-text {
  margin-bottom: 0;
}

.footer-bottom-links {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap;
}

.footer-bottom-links a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-bottom-links a:hover {
  color: #ff4e50;
}

.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 999;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.back-to-top.active {
  opacity: 1;
  visibility: visible;
}

.back-to-top:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .footer-section {
    padding-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .social-links {
    justify-content: center;
    margin-top: 1rem;
  }

  .footer-bottom-links {
    justify-content: center;
    margin-top: 1rem;
  }

  .copyright-text {
    text-align: center;
  }

  .back-to-top {
    bottom: 20px;
    right: 20px;
  }
}
</style>
