<template>
  <div id="app">
    <div class="">

      <div class="">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style>
html, body {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  position: relative;

}

#app, .app-container {  
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}
</style>


