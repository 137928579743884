import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import AgentSelection from '@/views/AgentSelection.vue'
import MainPage from '@/views/MainPage.vue'
import store from '../store/index';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';

function isLoggedIn() {
  const token = localStorage.getItem('token');

  if (token) {
    store.dispatch('setToken', token);
    return true;
  } else {
    return false;
  }
}

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
  },

  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: PrivacyPolicy,
  },
  
  {
    path: '/agent-selection',
    name: 'AgentSelection',
    component: AgentSelection,
    beforeEnter: (to, from, next) => {
      next();
    },
  },

  {
    path: '/events/:name?',
    name: 'home',
    component: HomePage,
    props: true,
    beforeEnter: (to, from, next) => {
      const name = to.params.name;
      if (!name) {
        next({ name: 'AgentSelection' });
      } else {
        // Name is provided, so commit it to the store and proceed
        store.commit('setName', name);
        next();
       // next({ name: 'AgentSelection' });
      }
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router