<template>
  <ToastNotifications ref="toastNotificationsRef" />
  <section id="contact" class="contact-section py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center" data-aos="fade-up">
          <h2 class="section-title mb-5">Contact Us</h2>
        </div>
      </div>

      <div class="row g-4">
        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div class="contact-card">
            <div class="card-icon">
              <font-awesome-icon icon="fa-solid fa-location-dot" />
            </div>
            <h3>Location</h3>
            <p>Kenya National Theatre, Nairobi</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
          <div class="contact-card">
            <div class="card-icon">
              <font-awesome-icon icon="fa-solid fa-phone" />
            </div>
            <h3>Phone</h3>
            <p>+254 743 417198</p>
            <p>+254 704 130589</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
          <div class="contact-card">
            <div class="card-icon">
              <font-awesome-icon icon="fa-solid fa-envelope" />
            </div>
            <h3>Email</h3>
            <p>igizaarts@gmail.com</p>
            <p>info@igizaarts.co.ke</p>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-6" data-aos="fade-right" data-aos-delay="400">
          <div class="map-container">
            <div class="map-responsive">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.817742017488!2d36.81514441475652!3d-1.279908899070928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10d0ffeab097%3A0x45f85c46c28e86!2sKenya%20National%20Theatre!5e0!3m2!1sen!2ske!4v1646298851919!5m2!1sen!2ske"
                width="600"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              >
              </iframe>
            </div>
          </div>
        </div>

        <div class="col-lg-6" data-aos="fade-left" data-aos-delay="400">
          <div class="contact-form-container">
            <h3 class="form-title mb-4">Send us a message</h3>
            <form class="contact-form" @submit.prevent="submitForm">
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your Name"
                  v-model="formData.name"
                  required
                />
              </div>

              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Your Email"
                  v-model="formData.email"
                  required
                />
              </div>

              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Subject"
                  v-model="formData.subject"
                  required
                />
              </div>

              <div class="mb-3">
                <textarea
                  class="form-control"
                  rows="5"
                  placeholder="Your Message"
                  v-model="formData.message"
                  required
                ></textarea>
              </div>

              <button
                type="submit"
                class="btn btn-submit"
                :disabled="isSubmitting"
              >
                {{ isSubmitting ? "Sending..." : "Send Message" }}
                <font-awesome-icon
                  icon="fa-solid fa-paper-plane"
                  class="ms-2"
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import ToastNotifications from "@/components/ToastNotifications.vue";
export default {
  name: "ContactSection",
  components: { ToastNotifications },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      isSubmitting: false,
    };
  },
  methods: {
    async submitForm() {
      if (
        !this.formData.name ||
        !this.formData.email ||
        !this.formData.subject ||
        !this.formData.message
      ) {
        this.errorToast("All fields are required.");
        return;
      }

      this.isSubmitting = true;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}contact`,
          this.formData
        );

        this.successToast(response.data.message);
        this.isSubmitting = false;
        this.formData = { name: "", email: "", subject: "", message: "" }; // Reset form
      } catch (error) {
        this.isSubmitting = false;
        this.errorToast(
          error.response?.data?.error ||
            "Something went wrong. Please try again."
        );
      }
    },
    errorToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },

    successToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("success", message);
    },
  },
};
</script>

<style scoped>
.contact-section {
  background-color: #f8f9fa;
  padding: 100px 0;
}

.section-title {
  font-weight: 700;
  color: #333;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
}

.contact-card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
  height: 100%;
}

.contact-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.card-icon {
  font-size: 2.5rem;
  color: #ff4e50;
  margin-bottom: 1.5rem;
}

.contact-card h3 {
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.contact-card p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.map-container {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.contact-form-container {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.form-title {
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.form-control {
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  transition: all 0.3s ease;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ff4e50;
}

.btn-submit {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border: none;
  color: white;
  padding: 12px 25px;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
  width: 100%;
}

.btn-submit:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 78, 80, 0.3);
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .contact-section {
    padding: 80px 0;
  }

  .contact-form-container {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .contact-section {
    padding: 60px 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .contact-form-container {
    padding: 30px 20px;
  }
}
</style>
