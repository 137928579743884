<template>
  <section class="team-carousel-section py-5" id="team">
    <div class="container">
      <h2 class="section-title text-center mb-5" data-aos="fade-up">
       Meet the Team
      </h2>

      <!-- Management Team -->
      <div class="management-team mb-5">
        <div class="row justify-content-center">
          <div
            class="col-md-6 col-lg-4 mb-4"
            v-for="(member, index) in managementTeam"
            :key="'management-' + index"
            data-aos="fade-up"
            :data-aos-delay="index * 100"
          >
            <div class="team-card">
              <div class="team-image">
                <img :src="member.photo" :alt="member.name" class="img-fluid" />
              </div>
              <div class="team-info">
                <h4 class="member-name">{{ member.name }}</h4>
                <p class="member-role">{{ member.role }}</p>
                <p class="member-bio">{{ member.bio }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Actors Manual Slider (No Swiper) -->
      <div class="actors-section" data-aos="fade-up">
        <div class="actors-carousel-container">
          <!-- Navigation arrows -->
          <button class="custom-button-prev" @click="prevSlide">
            <font-awesome-icon icon="fa-solid fa-chevron-left" />
          </button>

          <div class="actors-slider">
            <div
              class="actors-track"
              :style="{
                transform: `translateX(${-currentSlide * (100 / itemsPerSlide)}%)`,
              }"
            >
              <div
                class="actor-card"
                v-for="(actor, index) in actors"
                :key="'actor-' + index"
                :style="{ width: `calc(${100 / itemsPerSlide}% - 30px)` }"
              >
                <div class="actor-image">
                  <img :src="actor.photo" :alt="actor.name" class="img-fluid" />
                  <div class="actor-overlay">
                    <h5>{{ actor.name }}</h5>
                  </div>
                </div>
                <div class="actor-info">
                  <h4 class="actor-name">{{ actor.name }}</h4>
                  <p class="actor-role">{{ actor.primaryRole }}</p>
                </div>
              </div>
            </div>
          </div>

          <button class="custom-button-next" @click="nextSlide">
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </button>
        </div>

        <!-- Pagination dots -->
        <div class="slider-pagination">
          <span
            v-for="(_, index) in paginationDots"
            :key="index"
            class="pagination-dot"
            :class="{
              active: Math.floor(currentSlide / itemsPerSlide) === index,
            }"
            @click="goToSlide(index * itemsPerSlide)"
          ></span>
        </div>
      </div>

      <!-- Join Our Team CTA -->
      <div class="join-team-cta mt-5 text-center" data-aos="fade-up">
        <h3 class="cta-title">Join Our Creative Family</h3>
        <p class="cta-text">
          Are you passionate about theatre and looking to be part of something
          extraordinary?<br />
          We're always on the lookout for talented individuals to join our team.
        </p>
        <a href="#contact" class="btn btn-join-team">
          Explore Opportunities
          <font-awesome-icon icon="fa-solid fa-user-plus" class="ms-2" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TeamCarouselSection",
  data() {
    return {
      currentSlide: 0,
      cardGap: 30, // Gap between cards
      itemsPerSlide: 4, // Default items per slide, will be updated based on viewport
      managementTeam: [
        {
          name: "Wreiner Arnold Mandu",
          role: "Producer",
          photo: require("@/assets/images/wreiner.jpeg.webp"), 
          bio: "A producer with over a decade of experience in theatre production and management.He oversees productions from concept to execution, ensuring high-quality performances and smooth operations",
          social: [
            { icon: "twitter", link: "https://twitter.com/" },
            { icon: "linkedin", link: "https://linkedin.com/" },
            { icon: "instagram", link: "https://instagram.com/" },
          ],
        },
        {
          name: "Martin Abuya",
          role: "Creative Director & Playwright",
          photo: require("@/assets/images/martin.jpeg.webp"), 
          bio: "Award-winning visionary director and renowned playwright known for creating compelling narratives that reflect Kenyan culture, pushing artistic boundaries, mentoring emerging talent, and bringing thought-provoking stories to life on stage",
          social: [
            { icon: "twitter", link: "https://twitter.com/" },
            { icon: "linkedin", link: "https://linkedin.com/" },
            { icon: "instagram", link: "https://instagram.com/" },
          ],
        },
        {
          name: "Paul Bett",
          role: "Videographer & Technical Coordinator",
          photo: require("@/assets/images/paul.webp"), 
          bio: "A skilled Videographer & Technical Coordinator with a keen eye for detail and a passion for capturing compelling visuals. He specializes in bringing stories to life through seamless production and technical expertise",
          social: [
            { icon: "twitter", link: "https://twitter.com/" },
            { icon: "linkedin", link: "https://linkedin.com/" },
            { icon: "instagram", link: "https://instagram.com/" },
          ],
        }
      ],
      actors: [
        {
          name: "Sam Ouma",
          primaryRole: "Actor",
          photo: require("@/assets/images/ouma.jpg.webp"),
          featuredIn: "Flight 1963, Threads of Trust",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Edna Kariuki",
          primaryRole: "Actress",
          photo: require("@/assets/images/edna.jpg.webp"),
          featuredIn: "Shadows of the Soul, Threads of Trust",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Jeff Obonyo",
          primaryRole: "Actor",
          photo: require("@/assets/images/jeff.jpg.webp"),
          featuredIn: "An Ideal Husband, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Milkah Wangui",
          primaryRole: "Actress",
          photo: require("@/assets/images/milkah.jpg.webp"),
          featuredIn: "Sins and Secrets, Threads of Trust",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Ann Kiveli",
          primaryRole: "Actress",
          photo: require("@/assets/images/kiveli.webp"),
          featuredIn: "White Lie, Threads of Trust",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Harold Kerry",
          primaryRole: "Actor & Software Engineer",
          photo: require("@/assets/images/harold.jpeg.webp"),
          featuredIn: "White Lie, Threads of Trust",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Tashar Moraa",
          primaryRole: "Actress",
          photo: require("@/assets/images/tarshar.webp"),
          featuredIn: "Cherry Child, Ideal Husband",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Sammy Waweru",
          primaryRole: "Actor",
          photo: require("@/assets/images/wesh.webp"),
          featuredIn: "White Lie, Threads of Trust",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Linda Kamuri",
          primaryRole: "Actress",
          photo: require("@/assets/images/kamuri.jpg.webp"),
          featuredIn: "Cherry Child, Ideal Husband",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Prince Kamselele",
          primaryRole: "Actor",
          photo: require("@/assets/images/kamselele.jpg.webp"),
          featuredIn: "The Architect, Threads of Trust",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Faith Karimi",
          primaryRole: "Actress & Data Scientist",
          photo: require("@/assets/images/karimz.jpg.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Lucarelli Onyango",
          primaryRole: "Actor",
          photo: require("@/assets/images/luca.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Lydia Mamati",
          primaryRole: "Actress, Editor & Photograher",
          photo: require("@/assets/images/mamati.jpg.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Brian Mutwiri",
          primaryRole: "Actor",
          photo: require("@/assets/images/mutwiri.jpg.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Lisa Grace",
          primaryRole: "Actress",
          photo: require("@/assets/images/lisa.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Francis Nzau",
          primaryRole: "Musician & Performing Artist",
          photo: require("@/assets/images/sajoh.jpg.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Sandra Rakwar",
          primaryRole: "Set Designer",
          photo: require("@/assets/images/sandra.jpg.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Alfan",
          primaryRole: "Actor",
          photo: require("@/assets/images/alfan.jpg.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Nancy Happy",
          primaryRole: "Actress",
          photo: require("@/assets/images/happy.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Angie Mbithe",
          primaryRole: "Actress",
          photo: require("@/assets/images/angie.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Joy Adhiambo",
          primaryRole: "Actress",
          photo: require("@/assets/images/joy.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        {
          name: "Kibagendi",
          primaryRole: "Actress",
          photo: require("@/assets/images/kibagendi.webp"),
          featuredIn: "Severed Justice, Flight 1963",
          social: [
            { icon: "instagram", link: "https://instagram.com/" },
            { icon: "twitter", link: "https://twitter.com/" },
          ],
        },
        
      ],
    };
  },
  computed: {
    // Calculate total number of slides
    totalSlides() {
      return Math.ceil(this.actors.length / this.itemsPerSlide);
    },
    // Generate pagination dots
    paginationDots() {
      return Array(this.totalSlides).fill(0);
    },
    // Calculate maximum slide index
    maxSlideIndex() {
      return this.actors.length - this.itemsPerSlide;
    }
  },
  methods: {
    // Navigate to the previous slide
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.maxSlideIndex;
      }
    },

    // Navigate to the next slide
    nextSlide() {
      if (this.currentSlide < this.maxSlideIndex) {
        this.currentSlide++;
      } else {
        this.currentSlide = 0;
      }
    },
 
    goToSlide(index) {
      this.currentSlide = Math.min(index, this.maxSlideIndex);
    },

    // Adjust items per slide based on screen width
    updateItemsPerSlide() {
      if (window.innerWidth >= 1200) {
        this.itemsPerSlide = 4;
      } else if (window.innerWidth >= 992) {
        this.itemsPerSlide = 3;
      } else {
        this.itemsPerSlide = 1; 
      }

      // Reset current slide if needed
      if (this.currentSlide > this.maxSlideIndex) {
        this.currentSlide = this.maxSlideIndex;
      }
    },
  },
  mounted() {
    // Set responsive items per slide on mount
    this.updateItemsPerSlide();

    // Auto-advance slides every 3 seconds
    this.autoplayInterval = setInterval(() => {
      this.nextSlide();
    }, 3000);

    // Update layout on window resize
    window.addEventListener("resize", this.updateItemsPerSlide);
  },
  beforeUnmount() {
    // Clean up
    clearInterval(this.autoplayInterval);
    window.removeEventListener("resize", this.updateItemsPerSlide);
  },
};
</script>

<style scoped>
.team-carousel-section {
  background-color: #f8f9fa;
  padding: 100px 0;
}

.section-title {
  font-weight: 700;
  color: #333;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
}

/* Management Team Styles */
.team-card {
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
}

.team-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.team-image {
  position: relative;
  overflow: hidden;
}

.team-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.team-card:hover .team-image img {
  transform: scale(1.1);
}

.team-info {
  padding: 20px;
  text-align: center;
}

.member-name {
  font-weight: 700;
  color: #333;
  margin-bottom: 5px;
}

.member-role {
  color: #ff4e50;
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.member-bio {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.6;
}

.actors-section {
  margin-top: 50px;
}

.actors-carousel-container {
  position: relative;
  padding: 0 50px;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1200px;
}

.actors-slider {
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 5px;
}

.actors-track {
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
}

.actor-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin: 0 15px;
  flex-shrink: 0;
}

.actor-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.actor-image {
  position: relative;
  overflow: hidden;
}

.actor-image img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.actor-card:hover .actor-image img {
  transform: scale(1.1);
}

.actor-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.actor-card:hover .actor-overlay {
  opacity: 1;
}

.actor-overlay h5 {
  color: white;
  font-weight: 700;
  margin-bottom: 5px;
}

.actor-info {
  padding: 15px;
  text-align: center;
}

.actor-name {
  font-weight: 700;
  color: #333;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

.actor-role {
  color: #ff4e50;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.custom-button-prev,
.custom-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
  border: none;
}

.custom-button-prev {
  left: 0;
}

.custom-button-next {
  right: 0;
}

.custom-button-prev:hover,
.custom-button-next:hover {
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 5px 15px rgba(255, 78, 80, 0.3);
}

.slider-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 8px;
}

.pagination-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-dot.active {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  transform: scale(1.2);
}

/* Join Team CTA */
.join-team-cta {
  margin-top: 80px;
  padding: 50px;
  border-radius: 15px;
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  color: white;
}

.cta-title {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 2rem;
}

.cta-text {
  max-width: 600px;
  margin: 0 auto 30px;
  font-size: 1.1rem;
  line-height: 1.8;
}

.btn-join-team {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border: none;
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-join-team:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 78, 80, 0.3);
  color: white;
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .team-carousel-section {
    padding: 80px 0;
  }

  .join-team-cta {
    padding: 40px 30px;
  }

  .cta-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 767px) {
  .team-carousel-section {
    padding: 60px 0;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .team-image img {
    height: 250px;
  }

  .join-team-cta {
    padding: 30px 20px;
  }

  .cta-title {
    font-size: 1.5rem;
  }

  .cta-text {
    font-size: 1rem;
  }

  .actor-image img {
    height: 280px;
  }
  
  .actors-carousel-container {
    padding: 0 40px;
  }
}

@media (max-width: 575px) {
  .team-image img {
    height: 250px;
  }

  .actor-image img {
    height: 280px;
  }

  .actors-carousel-container {
    padding: 0 35px;
  }

  .custom-button-prev,
  .custom-button-next {
    width: 30px;
    height: 30px;
  }
  
  /* Center the single card in mobile view */
  .actor-card {
    margin: 0 15px;
    width: calc(100% - 30px) !important;
  }
}
</style>