<template>
  <nav class="navbar navbar-expand-md fixed-top shadow-sm bg-white">
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <!-- Logo on the left -->
      <a href="#" class="navbar-brand">
        <img src="../assets/images/igizalogo.png" alt="Igiza Logo" height="40" loading="lazy" />
      </a>

      <!-- Navigation Links -->
      <div class="collapse navbar-collapse justify-content-center">
        <ul class="navbar-nav">
          <li v-for="link in navLinks" :key="link.id" class="nav-item px-2">
            <a class="nav-link" :href="'#' + link.id">{{ link.label }}</a>
          </li>
        </ul>
      </div>

      <!-- Call Support Button (visible on both desktop and mobile) -->
      <a href="tel:+254743417198" class="btn btn-primary btn-hover px-3 d-none d-md-block">
        <font-awesome-icon icon="fa-solid fa-phone" class="me-2" />
        Call Us
      </a>

      <!-- Mobile Toggle Button -->
      <button class="navbar-toggler d-md-none" type="button" @click="toggleMenu">
        <font-awesome-icon icon="fa-solid fa-bars" />
      </button>
    </div>

    <!-- Mobile Navigation -->
    <div :class="['collapse navbar-collapse', { show: isMenuOpen }]">
      <ul class="navbar-nav d-md-none text-center">
        <li v-for="link in navLinks" :key="link.id" class="nav-item px-2">
          <a class="nav-link" :href="'#' + link.id" @click="closeMenu">{{ link.label }}</a>
        </li>
        <!-- Mobile "Call Us" Button -->
        <li class="nav-item px-2 mt-2">
          <a href="tel:+254743417198" class="mb-1 btn btn-primary w-100">
            <font-awesome-icon icon="fa-solid fa-phone" class="me-2" />
            Call Us
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false,
      navLinks: [
        { id: "about", label: "About" },
        { id: "services", label: "Services" },
        { id: "events", label: "Events" },
        { id: "team", label: "The Team" },
        { id: "gallery", label: "Gallery" },
        { id: "contact", label: "Contact" },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style scoped>

.navbar {
  padding: 10px 0;
}



.btn-primary {
  margin-right: 20px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border: none;
  border-radius: 20px;
  padding: 10px 25px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(249, 212, 35, 0.3);
}

/* Mobile Styling */
@media (max-width: 768px) {
  .navbar-nav {
    text-align: center;
  }
  .nav-link {
    padding: 10px;
    display: block;
  }
  .call-btn {
    font-size: 16px;
    padding: 10px;
  }
}
</style>
