<template>
  <section class="">
    <div class="hero-section">
      <div class="overlay"></div>
      <div class="container-fluid text-center">
        <h6 class="hero-title">
          Hey there,
          <span class="fan-name">{{ fanName }}'s</span> fan!
        </h6>

        <Events />
      </div>
    </div>
   
  </section>
</template>

<script>
import "@/assets/styles/main.css";
import Events from "../components/EventCards.vue";

export default {
  computed: {
    fanName() {
      return this.$store.state.name || "Igiza";
    },
  },
  components: { Events },
};
</script>

<style scoped>
/* Background Image */
.hero-section {
  background: url("@/assets/images/back.jpg.webp") no-repeat center center/cover;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dark Overlay */
 .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

/* Hero Title */
.hero-title {
  position: relative;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.fan-name {
  background: linear-gradient(45deg, #c350e9, #ffffff);
  background-clip: text;
  -webkit-background-clip: text; /* For Safari & older Chrome */
  -webkit-text-fill-color: transparent; /* Ensures text appears as gradient */
  font-weight: bold;
}





</style>
