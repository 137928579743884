<template>
  <section id="about" class="about-section py-5">
    <div class="container">
      <!-- First Row - About Us -->
      <div class="row align-items-center mb-5">
        <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1000">
          <h2 class="section-title mb-4">About Us</h2>
          <p class="section-text">
            We are a passionate theatre production company that lives and
            breathes storytelling. From powerful dramas to lighthearted comedies
            we bring stories to life in a way that captivates, inspires and
            stays with you long after the curtains close. Driven by creativity
            and innovation, our team made up of talented artists, directors
            writers and production experts works together to craft unforgettable
            stage experiences.At the heart of it all? A love for theatre and the
            magic it creates
          </p>
        </div>
        <div
          class="col-lg-6"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <div class="about-image">
            <img
              src="../assets/images/about.jpg.webp"
              alt="Theatre performance"
              class="img-fluid rounded shadow"
            />
          </div>
        </div>
      </div>

      <!-- Second Row - Our Mission (Alternating) -->
      <div class="row align-items-center mb-5">
        <div
          class="col-lg-6 order-lg-2"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h2 class="section-title mb-4">Our Mission</h2>
          <p class="section-text">
            We are dedicated to nurturing local talent and bringing Kenyan
            stories to life on stage. Theatre has the power to entertain,
            educate, and inspire change, and we use it to amplify voices,
            celebrate our diverse culture, and spark meaningful conversations.
            Our productions provide a platform for emerging artists while
            sharing authentic Kenyan narratives with audiences both locally and
            beyond. Whether it is through drama, music, or storytelling, we aim
            to create unforgettable experiences that leave a lasting impact
          </p>
        </div>
        <div
          class="col-lg-6 order-lg-1"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <div class="about-image">
            <img
              src="../assets/images/mission.jpg.webp"
              alt="Theatre mission"
              class="img-fluid rounded shadow"
            />
          </div>
        </div>
      </div>

      <!-- Third Row - Igiza Academy (Alternating) -->
      <div class="row align-items-center">
        <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1000">
          <h2 class="section-title mb-4">Igiza Academy</h2>
          <p class="section-text">
            At Igiza Academy, we nurture the next generation of performers by
            building confidence on stage and beyond. Through hands-on training
            in public speaking, acting, and stage presence, young talents get to
            explore their creativity in a supportive and professional
            environment. Guided by experienced mentors, our students develop
            essential performance skills while gaining the confidence to express
            themselves—whether on stage or in everyday life
          </p>
          <a href="#contact" class="btn btn-outline-primary mt-3">
            Learn More
            <font-awesome-icon icon="fa-solid fa-arrow-right" class="ms-2" />
          </a>
        </div>
        <div
          class="col-lg-6"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <div class="about-image">
            <img
              src="../assets/images/academy.jpg.webp"
              alt="Igiza Academy"
              class="img-fluid rounded shadow"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSection",
};
</script>

<style scoped>
.about-section {
  padding: 100px 0;
  background-color: #121212;
  color: white;
}

.section-title {
  font-weight: 700;
  margin-bottom: 2rem;
  position: relative;
  color: white;
}

.section-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #fff;
}

.about-image {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.about-image img {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
}

.about-image:hover img {
  transform: scale(1.05);
}

.btn-outline-primary {
  border: 2px solid #ff4e50;
  color: #ff4e50;
  border-radius: 30px;
  padding: 10px 25px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-outline-primary:hover {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border-color: transparent;
  color: white;
  transform: translateY(-3px);
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .about-section {
    padding: 80px 0;
  }

  .row {
    margin-bottom: 60px;
  }

  .about-image {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .order-lg-1 {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .about-section {
    padding: 60px 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .about-image {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
</style>
