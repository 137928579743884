<template>
  <section class="hero-carousel" id="home">
    <div
      id="heroCarousel"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#heroCarousel"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#heroCarousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#heroCarousel"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>

      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="5000">
          <div class="overlay"></div>
          <div
            class="carousel-background"
            :style="{
              backgroundImage: `url(${require('@/assets/images/flightbanner.jpeg.webp')})`,
            }"
          ></div>
          <div class="carousel-caption">
            <h1 class="px-2 animate__animated animate__fadeIn">
              Igiza Arts Production
            </h1>
            <p class="animate__animated animate__fadeIn animate__delay-1s">
              Where Stories Come Alive
            </p>
            <div class="d-grid d-md-flex justify-content-center gap-3 mt-3">
              <!-- Explore Button (Scroll to About) -->
              <a
                href="#about"
                class="btn btn-primary animate__animated animate__fadeIn animate__delay-2s"
              >
                Explore More
                <font-awesome-icon icon="fa-solid fa-arrow-down" class="ms-2" />
              </a>

              <!-- Upcoming Events Button -->
              <a
                href="/events"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary animate__animated animate__fadeIn animate__delay-3s"
              >
                View Events
                <font-awesome-icon
                  icon="fa-solid fa-arrow-right"
                  class="ms-2"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="carousel-item" data-bs-interval="5000">
          <div class="overlay"></div>
          <div
            class="carousel-background"
            :style="{
              backgroundImage: `url(${require('@/assets/images/threads.jpeg.webp')})`,
            }"
          ></div>
          <div class="carousel-caption">
            <h1 class="px-2 animate__animated animate__fadeIn">
              Igiza Arts Production
            </h1>
            <p class="animate__animated animate__fadeIn animate__delay-1s">
              Storytelling in Motion
            </p>
            <div class="d-grid d-md-flex justify-content-center gap-3 mt-3">
              <!-- Explore Button (Scroll to About) -->
              <a
                href="#about"
                class="btn btn-primary animate__animated animate__fadeIn animate__delay-2s"
              >
                Explore More
                <font-awesome-icon icon="fa-solid fa-arrow-down" class="ms-2" />
              </a>

              <!-- Upcoming Events Button -->
              <a
                href="/events"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary animate__animated animate__fadeIn animate__delay-3s"
              >
                View Events
                <font-awesome-icon
                  icon="fa-solid fa-arrow-right"
                  class="ms-2"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="carousel-item" data-bs-interval="5000">
          <div class="overlay"></div>
          <div
            class="carousel-background"
            :style="{
              backgroundImage: `url(${require('@/assets/images/hero.jpg.webp')})`,
            }"
          ></div>
          <div class="carousel-caption">
            <h1 class="px-2 animate__animated animate__fadeIn">
              Igiza Arts Production
            </h1>
            <p class="animate__animated animate__fadeIn animate__delay-1s">
              Storytelling in Motion
            </p>
            <div class="d-grid d-md-flex justify-content-center gap-3 mt-3">
              <!-- Explore Button (Scroll to About) -->
              <a
                href="#about"
                class="btn btn-primary animate__animated animate__fadeIn animate__delay-2s"
              >
                Explore More
                <font-awesome-icon icon="fa-solid fa-arrow-down" class="ms-2" />
              </a>

              <!-- Upcoming Events Button -->
              <a
                href="/events"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-primary animate__animated animate__fadeIn animate__delay-3s"
              >
                View Events
                <font-awesome-icon
                  icon="fa-solid fa-arrow-right"
                  class="ms-2"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#heroCarousel"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>

      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#heroCarousel"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroCarousel",
};
</script>

<style scoped>
.hero-carousel {
  height: 100vh;
  position: relative;
}

.carousel,
.carousel-inner,
.carousel-item {
  height: 100%;
}

.carousel-background {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.carousel-caption {
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  max-width: 800px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.carousel-caption h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-family: "Playfair Display SC", serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.carousel-caption p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.btn-primary {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border: none;
  border-radius: 30px;
  padding: 10px 25px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(249, 212, 35, 0.3);
}

/* Responsive Adjustments */
@media (max-width: 1199px) {
  .carousel-caption h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 991px) {
  .carousel-caption h1 {
    font-size: 3rem;
  }

  .carousel-caption p {
    font-size: 1.3rem;
  }
}

@media (max-width: 767px) {
  .carousel-caption h1 {
    font-size: 2.5rem;
  }

  .carousel-caption p {
    font-size: 1.1rem;
  }
}

@media (max-width: 575px) {
  .carousel-caption h1 {
    font-size: 2rem;
  }
}
</style>
