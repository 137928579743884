<template>
  <section class="upcoming-events-section py-5" id="events">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10 text-center" data-aos="fade-up">
          <h2 class="section-title mb-4">Don't Miss Our Upcoming Shows</h2>
          <p class="section-description mb-5">Experience the magic of live theatre with Igiza Arts Production's upcoming performances</p>
          
          <div class="cta-box" data-aos="zoom-in" data-aos-delay="200">
            <div class="cta-content">
              <h3>Looking for our upcoming events?</h3>
              <p>Discover our latest productions, workshops, and special performances</p>
              <router-link to="/events" class="btn btn-cta mt-3">
                Events
                <font-awesome-icon icon="fa-solid fa-calendar-check" class="ms-2" />
              </router-link>
            </div>
            <div class="cta-decoration left"></div>
            <div class="cta-decoration right"></div>
          </div>
          
        </div>
      </div>
    </div>
  </section>
</template>

<script>

</script>

<style scoped>
.upcoming-events-section {
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.section-title {
  font-weight: 700;
  color: #333;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
}

.section-description {
  color: #666;
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
}

.cta-box {
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  color: white;
  border-radius: 15px;
  padding: 60px 40px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.cta-content {
  position: relative;
  z-index: 2;
}

.cta-decoration {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  z-index: 1;
}

.cta-decoration.left {
  background: rgba(255, 78, 80, 0.1);
  top: -80px;
  left: -80px;
}

.cta-decoration.right {
  background: rgba(249, 212, 35, 0.1);
  bottom: -80px;
  right: -80px;
}

.btn-cta {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border: none;
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
  font-size: 1.1rem;
}

.btn-cta:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 78, 80, 0.3);
}

.event-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  height: 100%;
}

.event-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.event-date {
  position: absolute;
  top: 15px;
  right: 15px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  z-index: 2;
  font-weight: 700;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.event-date .day {
  display: block;
  font-size: 1.5rem;
  line-height: 1;
}

.event-date .month {
  display: block;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.event-image {
  height: 180px;
  overflow: hidden;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.event-card:hover .event-image img {
  transform: scale(1.1);
}

.event-details {
  padding: 20px;
}

.event-details h4 {
  font-weight: 700;
  margin-bottom: 10px;
  color: #333;
}

.location, .time {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.btn-ticket {
  background: #333;
  color: white;
  border: none;
  border-radius: 20px;
  transition: all 0.3s ease;
  padding: 5px 15px;
  margin-top: 10px;
}

.btn-ticket:hover {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
}

/* Responsive Adjustments */
@media (max-width: 767px) {
  .cta-box {
    padding: 40px 20px;
  }
  
  .event-preview {
    margin-top: 30px;
  }
  
  .section-title {
    font-size: 1.8rem;
  }
  
  .section-description {
    font-size: 1rem;
  }
}
</style>