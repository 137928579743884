<template>
  <section class="productions-section py-5">
    <div class="container">
      <h2 class="section-title text-center mb-5" data-aos="fade-up">Our Productions</h2>
      <div class="row g-4">
        <div class="col-md-6 col-lg-4" v-for="(production, index) in productionsWithImages" :key="index"
             data-aos="fade-up" 
             :data-aos-delay="index * 100">
          <div class="card h-100 production-card animate__animated animate__fadeIn">
            <div class="card-image-wrapper">
              <img :src="production.imageUrl" :alt="production.title" class="card-img-top" />
              <div class="card-overlay">
                <div class="overlay-content">
                  <h4>{{ production.title }}</h4>
                  <p>{{ production.description }}</p>
                </div>
              </div>
            </div>
            <div class="card-body d-flex flex-column">
              <h5 class="card-title fw-bold text-center">{{ production.title }}</h5>
              <!-- <p class="card-text text-center production-description mb-3">{{ production.description }}</p> -->
            
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </section>
</template>

<script>
// Import images
import flight1963Image from '@/assets/images/flight1963.jpg.webp'
import severed from '@/assets/images/severed.jpg.webp'
import ideal from '@/assets/images/ideal.jpg.webp'
import waiting from '@/assets/images/waiting.jpg.webp'
import threads from '@/assets/images/threadsImage.jpg.webp'
import miser from '@/assets/images/miser.jpg.webp'

export default {
  name: 'ProductionsSection',
  props: {
    productions: {
      type: Array,
      required: true
    }
  },
  computed: {
    productionsWithImages() {
      // Map to add proper image URLs
      return this.productions.map(production => {
        const fileName = this.getImageFileName(production.image);
        let imageUrl;
        
        // Match filename to imported image
        switch(fileName) {
          case 'flight1963.jpg.webp':
            imageUrl = flight1963Image;
            break;
          case 'severed.jpg.webp':
            imageUrl = severed;
            break;
          case 'ideal.jpg.webp':
            imageUrl = ideal;
            break;
          case 'waiting.jpg.webp':
            imageUrl = waiting;
            break;
          case 'threadsImage.jpg.webp':
            imageUrl = threads;
            break;
          case 'miser.jpg.webp':
            imageUrl = miser;
            break;
          default:
            
            imageUrl = require('@/assets/images/gallery/18.jpeg.webp');
        }
        
        return {
          ...production,
          imageUrl
        };
      });
    }
  },
  methods: {
    // Extract filename from path
    getImageFileName(path) {
      if (!path) return '';
      const parts = path.split('/');
      return parts[parts.length - 1];
    }
  }
}
</script>

<style scoped>
.productions-section {
  background-color: #1a1a1a;
  padding: 100px 0;
  color: white;
}

.section-title {
  font-weight: 700;
  color: white;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
}

.production-card {
  background: #ffffff;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.production-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.card-image-wrapper {
  position: relative;
  overflow: hidden;
}

.card-img-top {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.production-card:hover .card-img-top {
  transform: scale(1.1);
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.production-card:hover .card-overlay {
  opacity: 1;
}

.overlay-content {
  color: white;
  text-align: center;
  padding: 20px;
}

.overlay-content h4 {
  font-weight: 700;
  margin-bottom: 10px;
}

.card-title {
  color: #333;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.production-description {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.4;
}

.btn-view {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border: none;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  font-weight: 600;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-view:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 78, 80, 0.4);
  color: white;
}

.btn-view .fa-arrow-right {
  transition: transform 0.3s ease;
}

.btn-view:hover .fa-arrow-right {
  transform: translateX(5px);
}

.btn-all-productions {
  background-color: transparent;
  border: 2px solid #ff4e50;
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-all-productions:hover {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border-color: transparent;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 78, 80, 0.3);
  color: white;
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .productions-section {
    padding: 80px 0;
  }
}

@media (max-width: 767px) {
  .productions-section {
    padding: 60px 0;
  }
  
  .section-title {
    font-size: 1.8rem;
  }
  
  .card-img-top {
    height: 220px;
  }
}

@media (max-width: 575px) {
  .card-img-top {
    height: 200px;
  }
}
</style>