<template>
  <div class="container privacy-container">
    <div class="card shadow-lg p-4 position-relative">
      <!-- Close Button at Top Right -->
      <router-link to="/" class="close-btn">
        <font-awesome-icon icon="fa-solid fa-circle-xmark" />
      </router-link>

      <h3 class="modal-title mb-3">Privacy Policy</h3>

      <h5>Use of Contact Details for Ticket Details and Event Information</h5>
      <p class="text-sm">
        By proceeding with the ticket booking process on our website, you
        consent to the collection and use of your contact details, including but
        not limited to your name, email address, and phone number, for the
        purpose of providing you with ticket details and information related to
        the event(s) you have booked. We value your trust, and we are committed
        to ensuring the privacy and security of your personal information.
      </p>

      <h5>How We Use Your Contact Details</h5>
      <p class="text-sm">
        Ticket Details: We will use your contact details to send you important
        information related to your ticket purchase. This may include e-tickets,
        order confirmations, event updates, and any changes to event details,
        such as date, time, or venue.
      </p>

      <p class="text-sm">
        Event Information: We may also use your contact details to provide you
        with event-related information, such as event schedules, promotional
        offers, and relevant updates to enhance your event experience.
      </p>

      <h5>Data Security</h5>
      <p class="text-sm">
        We take the security of your personal information seriously. We have
        implemented technical and organizational measures to protect your data
        against unauthorized access, disclosure, alteration, and destruction.
      </p>

      <h5>Data Retention</h5>

      <p class="text-sm">
        We will retain your contact details for as long as necessary to fulfill
        the purposes outlined above or as required by applicable law. After the
        event is concluded, we will securely delete or anonymize your contact
        details.
      </p>

      <h5>Your Rights</h5>

      <p class="text-sm">
        You have the right to access, correct, or delete your personal
        information at any time. If you wish to exercise these rights or have
        any questions or concerns about our data privacy practices, please
        contact us.
      </p>

      <h5>Third-party Sharing</h5>

      <p class="text-sm">
        We do not share your contact details with event organizers, venues, or
        other parties involved in the event management process.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.container {
  max-width: 800px;
}

.privacy-container {
  margin-top: 10vh;
}

/* Close Button Styling */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
  color: #dc3545;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
}

.close-btn:hover {
  transform: scale(1.2);
  color: #a71d2a;
}
</style>
