<template>
  <section class="hero-section">
    <div class="hero-content">
      <div class="agent-select">
        <div class="container mt-5">
          <div
            class="container d-flex justify-content-center align-items-center loader-container"
            v-if="!membersLoaded"
          >
            <Loader />
          </div>
          <div class="modal-content"  v-if="membersLoaded">
            <h2 class="mb-3">Select Igiza Member</h2>
            <p class="text-muted">
              Choose Igiza (if you have no referral from a member)
            </p>
            <select class="form-select mb-3" v-model="selectedName">
              <option v-for="name in agentNames" :key="name" required>
                {{ name }}
              </option>
            </select>
            <button class="btn-custom" @click="selectName">Select</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader.vue";

export default {
  components: { Loader },
  data() {
    return {
      selectedName: null,
      agentNames: [], // Array to store agent usernames
      displayedEvents: [],
      membersLoaded: false,
    };
  },
  mounted() {
    this.fetchAgentNames(); // Call the method to fetch agent usernames when the component is mounted
    this.fetchEventData();
    this.fetchTicketData();
  },
  methods: {
    async fetchAgentNames() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}agents`
        ); // Fetch agent usernames from the API

        this.agentNames = response.data.agents.map((agent) => agent.username);
        // await new Promise((resolve) => setTimeout(resolve, 5000));
        this.membersLoaded = true;
      } catch (error) {}
    },
    selectName() {
      if (this.selectedName) {
        this.$store.commit("setName", this.selectedName);
        this.$router.push({ path: `/events/${this.selectedName}` });
      }
    },

    async fetchEventData() {
      this.$store.commit("clearUserData");
      try {
        // Make API call to fetch events data
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}events`
        );

        // Store the fetched events data in Vuex store
        this.$store.dispatch("setEvents", response.data);
      } catch (error) {}
    },
    async fetchTicketData() {
      this.$store.commit("clearUserData");
      try {
        // Make API call to fetch tickets data
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}tickets`
        );

        // Store the fetched tickets data in Vuex store
        this.$store.dispatch("setTickets", response.data);
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.hero-section {
  position: relative;
  height: 100vh; /* Full screen height */
  display: flex;
  align-items: flex-start; /* Align content near the top */
  justify-content: center;
  padding-top: 5rem; /* Push content down slightly */
  background: url("@/assets/images/back.jpg.webp") no-repeat center center/cover;
}

/* Dark Overlay */
.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay effect */
}

/* Content wrapper to prevent it from being affected by flex alignment */
.hero-content {
  position: relative; /* Ensures content is above overlay */
  z-index: 2;
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px; /* Optional: Adjust padding as needed */
  background: rgba(
    0,
    0,
    0,
    0.7
  ); /* Optional: Adds a background for better readability */
  z-index: 10; /* Ensures it stays above other elements */
}

.loader-container {
  margin-top: 20vh;
}
</style>
