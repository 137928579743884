<template>
    <div v-if="showPopup" class="modal d-block" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-4 position-relative">
          <!-- Close Button (X inside a circle, fully clickable) -->
          <button class="popup-close" @click="closePopup">
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
  
          <!-- Offer Details -->
          <div class="modal-body text-center">
            <!-- <h5 class="fw-bold">Early Bird Offers for <br /> The Anointed Cartel</h5> -->
            <img src="../assets/images/igizaPoster.webp" class="img-fluid mt-4"/>
            <p class="text-muted mt-4">Grab your early bird tickets now before prices go up!</p>
  
            <!-- Styled Offer Button -->
            <a href="/events" class="btn btn-custom-two">Get Offer</a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PopUp",
    data() {
      return {
        showPopup: true, // Show the popup on page load
      };
    },
    methods: {
      closePopup() {
        this.showPopup = false;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Background overlay */
  .modal {
    background: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }
  
  /* Modal size */
  .modal-dialog {
    max-width: 350px; /* Smaller card size */
  }
  

  
  .btn-close-custom:hover {
    color: #d32f2f; /* Darker red on hover */
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
  /* Remove default link styling */
  a {
    text-decoration: none;
    display: inline-block;
  }

  .popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: white;
  border: none;
  color: #333;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.popup-close:hover {
  transform: rotate(90deg);
  background: #ff4e50;
  color: white;
}

.btn-custom-two {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border: none;
  border-radius: 30px;
  padding: 10px 25px;
  font-weight: 600;
  transition: all 0.3s ease;
  color: white;
}

.btn-btn-custom-two:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(249, 212, 35, 0.3);
}

  </style>
  