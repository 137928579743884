<template>
 
  <div class="theatre-website">
    <NavigationBar />
    <!-- Hero Carousel Section -->
    <HeroCarousel />

    <!-- About Section -->
    <AboutSection />

    <!-- Services Section -->
    <ServicesSection :services="services" />

    <!-- Productions Gallery -->
    <ProductionsSection :productions="productions" />

    <!-- Upcoming Events CTA -->
    <UpcomingEventsSection />

    <!-- Team Carousel Section -->
    <TeamCarouselSection />

    <!-- Gallery Section -->
    <GallerySection />

   
    <!-- Contact Section -->
    <ContactSection />

    <!-- Footer -->
    <FooterSection />
    <Whatsapp />
    <PopUp />
  </div>
</template>

<script>
import HeroCarousel from "@/components/HeroCarousel.vue";
import AboutSection from "@/components/AboutSection.vue";
import ServicesSection from "@/components/ServicesSection.vue";
import ProductionsSection from "@/components/ProductionsSection.vue";
import UpcomingEventsSection from "@/components/UpcomingEventsSection.vue";
import TeamCarouselSection from "@/components/TeamCarouselSection.vue";
import GallerySection from "@/components/GallerySection.vue";
import ContactSection from "@/components/ContactSection.vue";
import FooterSection from "@/components/FooterSection.vue";
import Whatsapp from "@/components/Whatsapp.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import PopUp from "@/components/PopUp.vue";

export default {
  name: "TheatreWebsite",
  components: {
    HeroCarousel,
    AboutSection,
    ServicesSection,
    ProductionsSection,
    UpcomingEventsSection,
    TeamCarouselSection,
    GallerySection,
    ContactSection,
    FooterSection,
    Whatsapp,
    NavigationBar,
    PopUp
  },
  data() {
    return {
      services: [
        {
          title: "Stage Productions",
          description:
            "We bring stories to life through original and adapted plays, musicals and captivating theatrical performances, delivering unforgettable experiences for our audiences",
          icon: "fa-solid fa-masks-theater",
        },
        {
          title: "Event Productions",
          description:
            "We create unforgettable live theatrical performances tailored for private celebrations, corporate functions and special events",
          icon: "fa-solid fa-calendar-days",
        },
        {
          title: "Talent Development",
          description:
            "Through Igiza Academy, we nurture young talent by building confidence, enhancing public speaking skills, and refining acting techniques.",
          icon: "fa-solid fa-graduation-cap",
        },
        {
          title: "Community Engagement",
          description:
            "We collaborate with schools, arts organizations, and local communities to make theatre more accessible and impactful.",
          icon: "fa-solid fa-handshake",
        },
        {
          title: "Scriptwriting & Directing",
          description:
            "We craft compelling scripts and provide expert direction to bring stage productions to life with creativity and precision.",
          icon: "fa-solid fa-pen-to-square",
        },
        {
          title: "Theatre Consultancy",
          description:
            "We offer professional guidance on stage design, lighting, sound, and overall production management to enhance theatrical experiences.",
          icon: "fa-solid fa-lightbulb",
        },
      ],
      productions: [
        {
          title: "Flight 1963",
          description:
            "A powerful stage production that explores Kenya's journey from independence to the present day. Written by Martin Abuya, the play weaves historical events with political transitions, capturing the struggles, triumphs, and resilience of the nation",
          image: "../assets/images/flight1963.jpg.webp",
        },
        {
          title: "Severed Justice",
          description:
            "A gripping stage play that explores the current political landscape and the realities of police brutality. Through thought-provoking storytelling and dynamic performances, Severed Justice examines themes of power, resistance, and the pursuit of justice in contemporary society",
          image: "../assets/images/severed.jpg.webp",
        },
        {
          title: "An Ideal Husband",
          description:
            "An adaptation of Oscar Wilde's classic play, this production delves into themes of blackmail, political corruption, and the delicate balance between public reputation and personal integrity. With sharp wit and engaging drama, An Ideal Husband explores the consequences of secrets and the true meaning of honor.",
          image: "../assets/images/ideal.jpg.webp",
        },
        {
          title: "The Waiting Room",
          description:
            "A poignant exploration of the ongoing health crisis in the public sector, this play delves into the struggles of a dysfunctional family as they navigate a medical emergency affecting one of their own. Through raw emotion and compelling storytelling, The Waiting Room highlights the challenges of access to healthcare, strained family dynamics, and the resilience of the human spirit in times of crisis.",
          image: "../assets/images/waiting.jpg.webp",
        },
        {
          title: "Threads Of Trust",
          description:
            "A captivating Valentine's production that delves into the complexities of love and trust in relationships. Through an experimental narrative, Threads of Trust explores the delicate bonds that hold people together, the challenges of betrayal, and the journey toward forgiveness and understanding.",
          image: "../assets/images/threads.jpg.webp",
        },
        {
          title: "The Miser",
          description:
            "An adaptation of Molière's classic 17th-century comedy, The Miser (L'Avare), which satirizes the pitfalls of greed and miserliness. The play centers on Harpagon, a wealthy yet stingy man whose obsession with hoarding wealth leads to comedic conflicts within his family and social circle. Through witty dialogue and humorous situations, The Miser offers timeless commentary on the absurdity of placing material wealth above personal relationships and happiness",
          image: "../assets/images/miser.jpg.webp",
        },
      ],
    };
  },
  mounted() {
    // Initialize AOS animation library
    if (typeof AOS !== "undefined") {
      AOS.init({
        duration: 800,
        easing: "ease-in-out",
        once: true,
      });
    }
  },
};
</script>

<style scoped>
@import "animate.css";

/* Global Variables */
:root {
  --primary-color: #ff4e50;
  --secondary-color: #f9d423;
  --dark-color: #1a1a1a;
  --light-color: #f8f9fa;
  --text-dark: #333;
  --text-muted: #666;
  --gradient: linear-gradient(45deg, #ff4e50, #f9d423);

  /* Scrollbar Colors */
  --scrollbar-track: #f1f1f1;
  --scrollbar-thumb: #ff4e50;
  --scrollbar-thumb-hover: #f9d423;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 6px;
  transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

* {
  max-width: 100%;
  box-sizing: border-box;
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  scroll-behavior: smooth;
  
}

/* Global Styles */
.section-title {
  font-weight: 700;
  position: relative;
}

.btn {
  border-radius: 30px;
  padding: 10px 25px;
  font-weight: 600;
  transition: all 0.3s ease;
}



/* Prevent FOUC (Flash of Unstyled Content) */
.theatre-website {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: 0.1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Common Animation Classes */
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.fade-in-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Common Hover Effects */
.hover-lift {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Media Query Variables */
@media (max-width: 1199px) {
  :root {
    --heading-large: 3.5rem;
    --heading-medium: 2.5rem;
    --heading-small: 1.8rem;
  }
}

@media (max-width: 991px) {
  :root {
    --heading-large: 3rem;
    --heading-medium: 2.2rem;
    --heading-small: 1.6rem;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
}

@media (max-width: 767px) {
  :root {
    --heading-large: 2.5rem;
    --heading-medium: 2rem;
    --heading-small: 1.5rem;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
}

@media (max-width: 575px) {
  :root {
    --heading-large: 2rem;
    --heading-medium: 1.8rem;
    --heading-small: 1.4rem;
  }
}
</style>
