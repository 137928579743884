<template>
  <!--Toast Alert Component is imported here-->
  <ToastNotifications ref="toastNotificationsRef" />
  <div
    class="container d-flex justify-content-center align-items-center"
    v-if="!eventLoaded"
  >
    <Loader />
  </div>

  <div class="container-fluid mt-4" v-if="eventLoaded">
    <div class="row justify-content-center">
      <div
        :key="displayedEvents.id"
        class="col-12 col-sm-6 col-md-6 col-lg-3 mb-3"
      >
        <div class="card mb-2">
          <div class="card-body">
            <img
              :src="displayedEvents.eventImage"
              class="card-img-top"
              :alt="displayedEvents.name"
              width="200"
              height="400"
              loading="lazy"
            />

            <div class="card-text mt-2">
              <strong>Venue:</strong> {{ displayedEvents.eventLocation }}
            </div>

            <button
              class="btn-custom w-100 mt-2"
              data-bs-toggle="modal"
              :data-bs-target="'#eventModal' + displayedEvents.id"
              @click="selectedEventData(displayedEvents.id)"
            >
              <font-awesome-icon icon="fa-solid fa-briefcase" />
              <span class="mx-2">Seal the Deal</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-for="event in events"
    :key="event.id"
    :id="'eventModal' + event.id"
    class="modal"
    tabindex="-1"
    aria-labelledby="eventModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" v-if="selectedEvent">
        <div
          class="modal-header d-flex justify-content-center align-items-center"
        >
          <h5 class="modal-title text-truncate" id="eventModalLabel">
            <strong>{{ selectedEvent.eventName }}</strong>
          </h5>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="btn-container text-center">
                <button
                  type="button"
                  class="btn-custom"
                  :class="{ active: showDetails }"
                  @click="
                    showDetails = true;
                    showSeats = false;
                    showTickets = false;
                    showCheckout = false;
                    confirmationData = false;
                  "
                >
                  Event Details
                </button>

                <button
                  type="button"
                  class="btn-custom ms-2"
                  :class="{ active: showTickets }"
                  @click="
                    showTickets = true;
                    showDetails = false;
                    showSeats = false;
                    showCheckout = false;
                    confirmationData = false;
                    resetSeats();
                  "
                >
                  Buy Tickets
                </button>
              </div>

              <div v-if="showDetails" class="mt-3">
                <div class="row align-items-start">
                  <!-- Left Side: Event Date and Time -->
                  <div class="col-md-6">
                    <h5><strong>Event Date & Time</strong></h5>
                    <div v-for="date in eventDates" :key="date.id" class="mb-1">
                      <p>{{ date.date }}</p>
                    </div>
                  </div>

                  <!-- Right Side: Logo -->
                  <div
                    class="col-md-6 d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="../assets/images/volcha.png"
                      alt="Sponsors"
                      class="img-fluid"
                      width="200"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>

              <div v-if="showTickets" class="mt-3">
                <form
                  @submit.prevent="submitTicketForm"
                  class="p-3 card shadow mx-auto"
                >
                  <div class="form-group mb-3">
                    <label for="ticketType" class="mb-1">Ticket Type</label>
                    <select
                      id="ticketType"
                      class="form-control"
                      v-model="selectedTicketType"
                      required
                    >
                      <option value="" disabled>--Select Ticket--</option>
                      <option
                        v-for="ticket in ticketData"
                        :key="ticket.id"
                        :value="[
                          ticket.ticket_cost,
                          ticket.ticket_name,
                          ticket.event_id,
                        ]"
                      >
                        {{ ticket.ticket_name }} -
                        {{ ticket.ticket_description }} - Kshs
                        {{ ticket.ticket_cost }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-3">
                    <label for="eventTime" class="mb-1">Event Time</label>
                    <select
                      id="eventTime"
                      class="form-control"
                      v-model="selectedEventTime"
                      required
                    >
                      <option value="" disabled>--Select Date--</option>
                      <option
                        v-for="date in eventDates"
                        :key="date.id"
                        :value="date.id"
                      >
                        {{ date.date }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group mb-3">
                    <label for="ticketCount" class="mb-1"
                      >Number of Tickets</label
                    >
                    <div class="d-flex align-items-center">
                      <input
                        id="ticketCount"
                        type="number"
                        class="form-control w-85 text-center"
                        v-model="ticketCount"
                        min="1"
                        placeholder="Enter quantity"
                        required
                      />
                      <button
                        type="button"
                        class="btn-custom ms-2"
                        @click="increaseTicketCount"
                      >
                        +
                      </button>
                      <button
                        type="button"
                        class="btn-custom ms-2"
                        @click="decreaseTicketCount"
                      >
                        -
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn-custom w-100"
                    @click="fetchSeats(selectedEventTime)"
                  >
                    Proceed
                    <font-awesome-icon
                      icon="fa-solid fa-arrow-right"
                      class="icon fa-lg mx-1"
                    />
                  </button>
                </form>
              </div>

              <div v-if="showSeats" class="mt-2">
                <h5 class="text-center">
                  <strong>Check Seat Position</strong>
                </h5>
                <div
                  class="mt-1 chart-image d-flex justify-content-center align-items-center"
                >
                  <img
                    src="../assets/images/seatlayout.jpg.webp"
                    alt=""
                    class="img-fluid"
                    loading="lazy"
                  />
                </div>

                <SeatChart
                  :seatsData="seats"
                  v-if="seatDataLoaded"
                  @update:selectedSeats="updateSelectedSeats"
                />
                <div
                  v-if="noSeatsAvailable"
                  class="d-flex justify-content-center align-items-center"
                >
                  <div class="card shadow bg-danger">
                    <p class="text-white text-center text-bold mt-2 mx-2">
                      <font-awesome-icon
                        icon="fa-solid fa-circle-exclamation"
                        class="icon fa-sm"
                      /><span class="mx-1">No Seats</span>
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  class="btn-custom w-100 mt-1"
                  @click="checkout"
                  v-if="selectedSeats.length > 0"
                >
                  Proceed
                  <font-awesome-icon
                    icon="fa-solid fa-arrow-right"
                    class="icon fa-lg mx-1"
                  />
                </button>
              </div>

              <div v-if="showCheckout" class="mt-3">
                <h3 class="text-center"><strong>Checkout</strong></h3>
                <div class="mb-3 row">
                  <div
                    class="mpesaLogo mt-2 d-flex justify-content-center align-items-center col-12"
                  >
                    <img
                      src="../assets/images/lipa.png"
                      alt=""
                      height="20"
                      loading="lazy"
                    />
                  </div>
                  <div class="mt-2 col-12">
                    <p class="text-center amountValue">
                      Total Kshs {{ calculateTotalPrice() }}
                    </p>
                  </div>
                </div>

                <form @submit.prevent="purchaseCheckout">
                  <div class="mb-4">
                    <label for="email" class="form-label"
                      >Email Address<span class="text-danger">*</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      required
                      v-model="email"
                      placeholder="Enter your email"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="mobile" class="form-label"
                      >Mpesa No<span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="mobile"
                      v-model="mobile"
                      placeholder="Enter your Mpesa Number"
                      required
                    />
                  </div>

                  <div class="" v-if="noticeShown">
                    <div class="alert alert-info" role="alert">
                      <strong class="highlight">Important:</strong> Please
                      confirm the date and seats before proceeding with your
                      payment. No changes are allowed after booking.

                      <div class="mt-2">
                        <p>
                          <strong class="highlight">Ticket:</strong> A link to
                          download your ticket will be sent via SMS and Email
                          after payment
                        </p>
                        <p>
                          <strong class="highlight">Type of Ticket: </strong
                          >{{ selectedTicketType[1] }}
                        </p>
                        <p>
                          <strong class="highlight">Number of Tickets:</strong>
                          {{ ticketCount }}
                        </p>
                        <p>
                          <strong class="highlight">Date:</strong>
                          {{
                            (
                              eventDates.find(
                                (event) => event.id === selectedEventTime
                              ) || {}
                            ).date
                          }}
                        </p>
                        <p>
                          <strong class="highlight">Seats: </strong
                          >{{ selectedSeats.join(", ") }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <button @click="showBtns" class="btn-custom mb-2">
                        Confirmed
                        <font-awesome-icon icon="fa-solid fa-check-circle" />
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-outline-success w-100"
                    v-if="checkoutShown"
                    :disabled="confirmationData"
                  >
                    <span v-if="!confirmationData">
                      <font-awesome-icon icon="fa-solid fa-shopping-cart" />
                      Checkout</span
                    >
                    <span v-else>Processing...</span>
                  </button>
                </form>
              </div>

              <div v-if="confirmationData" class="mt-2" role="alert">
                <LoaderTwo />
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-danger"
            data-bs-dismiss="modal"
          >
            <span class=""></span> Close
            <font-awesome-icon icon="fa-solid fa-circle-xmark" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeatChart from "../components/SeatChart.vue";
import Loader from "../components/Loader.vue";
import LoaderTwo from "../components/LoaderTwo.vue";
import ToastNotifications from "@/components/ToastNotifications.vue";
import axios from "axios";
export default {
  components: { SeatChart, ToastNotifications, Loader, LoaderTwo },
  data() {
    const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
    return {
      seats: [],
      events: [],
      displayedEvents: [],
      eventDates: [],
      itemsPerPage: isSmallScreen ? 2 : 3,
      seatDataLoaded: false,
      noSeatsAvailable: false,
      noticeShown: true,
      checkoutShown: false,
      showDetails: true,
      showSeats: false,
      showTickets: false,
      showCheckout: false,
      selectedTicketType: {},
      selectedEvent: null,
      selectedEventTime: "",
      ticketCount: 1,
      ticketData: [],
      mpesaCode: "",
      amount: null,
      name: "",
      email: "",
      mobile: "",
      selectedSeats: [],
      agent: "",
      confirmationData: false,
      bookingData: [],
      eventLoaded: false,
    };
  },

  mounted() {
    this.eventLoaded = false;
    Promise.all([this.fetchEventData(), this.fetchTicketData()])
      .then(async () => {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.eventLoaded = true;
        // Once both fetches are completed, access the data from localStorage
        const storedEvents = JSON.parse(localStorage.getItem("events"));
        const storedTicket = JSON.parse(localStorage.getItem("tickets"));
        const eventDates = JSON.parse(localStorage.getItem("eventDates"));

        // Set the component data using the fetched data
        this.events = storedEvents;
        this.ticketData = storedTicket;
        this.displayedEvents = storedEvents[0];
        this.eventDates = eventDates;
      })
      .catch((error) => {
        this.handleError(error);
        // Handle any errors that occur during the fetch
      });
  },
  created() {
    this.agent = this.$store.state.name;
  },
  methods: {
    async fetchSeats(eventId) {
      this.checkoutShown = false;
      this.noticeShown = true;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}seats/${eventId}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );

        // Check if seats are available
        if (response.data.length > 0) {
          this.seats = response.data;
          this.seatDataLoaded = true;
          this.noSeatsAvailable = false;
        } else {
          this.seatDataLoaded = false;
          this.noSeatsAvailable = true;
          this.errorToast("Sold Out");
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    showBtns() {
      this.checkoutShown = true;
      this.noticeShown = false;
    },

    async purchaseCheckout() {
      this.confirmationData = true;

      const checkoutData = {
        email: this.email,
        mobile: this.mobile,
        ticket_count: this.ticketCount,
        agent: this.agent,
        ticket_name: this.selectedTicketType[1],
        seat_numbers: this.selectedSeats,
        event_id: this.selectedEventTime,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}checkout`,
          checkoutData,
          {
            headers,
          }
        );

        this.bookingData = response.data;

        (this.email = ""),
          (this.mobile = ""),
          (this.ticketCount = ""),
          (this.selectedTicketType = {}),
          (this.selectedSeats = ""),
          (this.selectedEvent = "");
        this.confirmationData = true;
        this.successToast("Check your phone, payment initiated");

        setTimeout(() => {
          this.$router.go(0);
        }, 4000);
      } catch (error) {
        this.confirmationData = false;
        this.handleError(error);
      }
    },

    async fetchEventData() {
      this.checkoutShown = false;
      this.noticeShown = true;
      this.$store.commit("clearUserData");
      try {
        // Make API call to fetch events data
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}events`
        );
        // Store the fetched events data in Vuex store
        this.$store.dispatch("setEvents", response.data);
      } catch (error) {
        this.handleError(error);
      }
    },
    async fetchTicketData() {
      this.$store.commit("clearUserData");
      try {
        // Make API call to fetch tickets data
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}tickets`
        );
        // Store the fetched tickets data in Vuex store
        this.$store.dispatch("setTickets", response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async handleError(error) {
      console.error(error);
      if (error.response && error.response.data) {
        // Check if the error response has data and if it's in JSON format
        const responseData = error.response.data;

        if (typeof responseData === "object" && responseData.error) {
          // If it's a JSON error response, use the error message from the response.

          if (responseData.error.searchTerm) {
            const searchTermError = responseData.error.searchTerm;
            this.errorToast(searchTermError);
          } else if (responseData.error.transactionCode) {
            const transCodeError = responseData.error.transactionCode;
            this.errorToast(transCodeError);
          } else {
            // Handle other error cases
            this.errorToast(responseData.error);
          }
        } else {
          // If it's not in the expected format, show a generic error message.
          this.errorToast("An error occurred");
        }
      } else {
        // If there's no error response data, show a generic error message.
        this.errorToast("An error occurred");
      }
    },
    //error toast notification
    errorToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },

    soldToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },

    //success toast notification
    successToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("success", message);
    },
    warningToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("warning", message);
    },

    selectedEventData(eventId) {
      this.selectedEvent = this.events.find((event) => event.id === eventId);
    },

    resetSeats() {
      this.selectedSeats = [];
    },

    decreaseTicketCount() {
      if (this.ticketCount > 1) {
        this.ticketCount--;
      }
    },

    increaseTicketCount() {
      this.ticketCount++;
    },
    calculateTotalPrice() {
      const price = this.selectedTicketType[0] || 0;
      return price * this.ticketCount;
    },
    submitTicketForm() {
      this.showSeats = true;
      this.showTickets = false;
      this.showCheckout = false;
      this.confirmationData = false;
    },

    checkout() {
      this.showSeats = false;
      this.showCheckout = true;
      this.confirmationData = false;
    },

    updateSelectedSeats(selectedSeats) {
      this.selectedSeats = selectedSeats;
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  text-align: left !important;
}

.alert {
  text-align: left !important;
}
</style>
