<template>
  <section class="gallery-section py-5" id="gallery">
    <div class="container">
      <h2 class="section-title text-center mb-5" data-aos="fade-up">Gallery</h2>
      
      
      <!-- Gallery Grid -->
      <div class="gallery-grid" ref="galleryGrid" data-aos="fade-up">
        <div 
          v-for="(item, index) in filteredGallery" 
          :key="index" 
          class="gallery-item"
          :class="[item.size, { 'show': true }]"
          @click="openLightbox(index)"
        >
          <div class="gallery-image">
            <img :src="item.image" :alt="item.title" class="img-fluid" loading="lazy">
            <div class="gallery-overlay">
              <div class="overlay-content">
               
                <span class="view-icon">
                  <font-awesome-icon icon="fa-solid fa-magnifying-glass-plus" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Lightbox -->
      <div class="lightbox" v-if="lightboxOpen" @click="closeLightbox">
        <div class="lightbox-content" @click.stop>
          <button class="lightbox-close" @click="closeLightbox">
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
          <div class="lightbox-img-container">
            <img :src="currentImage.image" :alt="currentImage.title" class="lightbox-img">
          </div>
          <!-- <div class="lightbox-info">
            <h3>{{ currentImage.title }}</h3>
            <p>{{ currentImage.description }}</p>
            <p class="lightbox-category">
              <span class="badge">{{ currentImage.category }}</span>
            </p>
          </div> -->
          <button class="lightbox-prev" @click.stop="prevImage" v-if="currentImageIndex > 0">
            <font-awesome-icon icon="fa-solid fa-arrow-left" />
          </button>
          <button class="lightbox-next" @click.stop="nextImage" v-if="currentImageIndex < filteredGallery.length - 1">
            <font-awesome-icon icon="fa-solid fa-arrow-right" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GallerySection',
  data() {
    return {
      activeFilter: 'all',
      currentItems: 9, // Initial number of items to show
      incrementItems: 6, // Number of items to add when loading more
      lightboxOpen: false,
      currentImageIndex: 0,
      gallery: [
        {
          image: require("@/assets/images/gallery/1.jpg.webp"),
          title: 'Igiza Team',
          description: 'Opening night performance at Kenya National Theatre',
          category: 'performances',
          size: 'large'
        },
        {
          image: require("@/assets/images/gallery/2.jpg.webp"),
          title: 'Igiza Team',
          description: 'Emotional scene from our latest production',
          category: 'performances',
          size: 'small'
        },
        {
          image: require("@/assets/images/gallery/3.jpg.webp"),
          title: 'Igiza Team',
          description: 'Cast preparing for opening night',
          category: 'performances',
          size: 'small'
        },
        {
          image: require("@/assets/images/gallery/4.jpg.webp"),
          title: 'Igiza Team',
          description: 'Contemporary adaptation of Shakespeare\'s classic',
          category: 'performances',
          size: 'medium'
        },
        {
          image: require("@/assets/images/gallery/5.jpg.webp"),
          title: 'Igiza Team',
          description: 'Igiza Arts receiving the Theatre Company of the Year award',
          category: 'performances',
          size: 'medium'
        },
        {
          image: require("@/assets/images/gallery/6.jpg.webp"),
          title: 'Igiza Team',
          description: 'Creative team working on set pieces',
          category: 'performances',
          size: 'small'
        },
        {
          image: require("@/assets/images/gallery/7.jpg.webp"),
          title: 'Igiza Team',
          description: 'Magical forest scene with full cast',
          category: 'performances',
          size: 'large'
        },
        {
          image: require("@/assets/images/gallery/8.jpg.webp"),
          title: 'Igiza Team',
          description: 'Teaching theatrical techniques to local youth',
          category: 'performances',
          size: 'medium'
        },
  
      ]
    }
  },
  computed: {
    filteredGallery() {
      if (this.activeFilter === 'all') {
        return this.gallery;
      }
      return this.gallery.filter(item => item.category === this.activeFilter);
    },
    visibleGallery() {
      return this.filteredGallery.slice(0, this.currentItems);
    },
    currentImage() {
      return this.filteredGallery[this.currentImageIndex] || this.filteredGallery[0];
    }
  },
  methods: {
    filterGallery(category) {
      this.activeFilter = category;
      this.currentItems = 9; // Reset to initial count when changing filter
      
      // Add small delay to allow CSS transitions to complete
      setTimeout(() => {
        this.initializeMasonry();
      }, 100);
    },
    loadMore() {
      this.currentItems += this.incrementItems;
      
      // Add small delay to allow new items to render
      setTimeout(() => {
        this.initializeMasonry();
      }, 100);
    },
    openLightbox(index) {
      this.currentImageIndex = index;
      this.lightboxOpen = true;
      document.body.classList.add('no-scroll');
    },
    closeLightbox() {
      this.lightboxOpen = false;
      document.body.classList.remove('no-scroll');
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
    },
    nextImage() {
      if (this.currentImageIndex < this.filteredGallery.length - 1) {
        this.currentImageIndex++;
      }
    },
    // Optional: Initialize Masonry layout if you want a more pinterest-like layout
    initializeMasonry() {
      // If you want to use Masonry.js for a more dynamic gallery layout, 
      // you can initialize it here.
      // This is commented out because it requires an external library.
      // You would need to install it with: npm install masonry-layout
      
      /*
      if (window.Masonry) {
        new window.Masonry(this.$refs.galleryGrid, {
          itemSelector: '.gallery-item',
          columnWidth: '.gallery-item.small',
          percentPosition: true
        });
      }
      */
    },
    // Method to load images from Google Drive
    loadGoogleDriveImages() {
      // Implementation depends on how you're accessing Google Drive
      // This is a placeholder for the actual implementation
      console.log('Loading images from Google Drive');
      
      // Example of how you might fetch a list of images from a Google Drive API endpoint
      /*
      fetch('YOUR_GOOGLE_DRIVE_API_ENDPOINT')
        .then(response => response.json())
        .then(data => {
          // Transform the Google Drive data into the format needed for the gallery
          const googleDriveImages = data.files.map(file => ({
            image: `https://drive.google.com/uc?id=${file.id}`,
            title: file.name,
            description: file.description || 'Theatre production image',
            category: this.determineCategoryFromMetadata(file),
            size: this.determineSizeFromDimensions(file)
          }));
          
          // Add these images to the gallery
          this.gallery = [...this.gallery, ...googleDriveImages];
          this.initializeMasonry();
        })
        .catch(error => {
          console.error('Error loading Google Drive images:', error);
        });
      */
    },
    determineCategoryFromMetadata(file) {
      // Logic to determine which category an image belongs to
      // This would depend on how you organize or tag your Google Drive files
      return 'performances'; // Default category
    },
    determineSizeFromDimensions(file) {
      // Logic to determine size class based on image dimensions
      // You could use the file dimensions if available in the metadata
      return 'medium'; // Default size
    }
  },
  mounted() {
    // Initialize gallery layout
    this.$nextTick(() => {
      this.initializeMasonry();
      
      // If you want to load from Google Drive
      // this.loadGoogleDriveImages();
    });
    
    // Handle keyboard navigation for lightbox
    window.addEventListener('keydown', (e) => {
      if (this.lightboxOpen) {
        if (e.key === 'Escape') {
          this.closeLightbox();
        } else if (e.key === 'ArrowLeft') {
          this.prevImage();
        } else if (e.key === 'ArrowRight') {
          this.nextImage();
        }
      }
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    window.removeEventListener('keydown', this.handleKeyDown);
  }
}
</script>

<style scoped>
.gallery-section {
  background-color: #f8f9fa;
  padding: 100px 0;
}

.section-title {
  font-weight: 700;
  color: #333;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
}

/* Gallery Filter Buttons */
.gallery-filter {
  margin-bottom: 30px;
}

.filter-btn {
  background: transparent;
  border: none;
  padding: 8px 20px;
  margin: 0 5px 10px;
  border-radius: 25px;
  font-weight: 600;
  color: #666;
  transition: all 0.3s ease;
  cursor: pointer;
}

.filter-btn:hover {
  color: #ff4e50;
}

.filter-btn.active {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  color: white;
  box-shadow: 0 5px 15px rgba(255, 78, 80, 0.3);
}

/* Gallery Grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  margin-bottom: 30px;
}

.gallery-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

.gallery-item.show {
  opacity: 1;
  transform: translateY(0);
}

.gallery-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Gallery Item Sizes */
.gallery-item.small {
  grid-row: span 1;
}

.gallery-item.medium {
  grid-row: span 2;
}

.gallery-item.large {
  grid-row: span 3;
  grid-column: span 2;
}

.gallery-image {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  overflow: hidden;
}

.gallery-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.gallery-item:hover .gallery-image img {
  transform: scale(1.1);
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.overlay-content {
  color: white;
}

.overlay-content h4 {
  font-weight: 700;
  margin-bottom: 10px;
}

.overlay-content p {
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.view-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  color: white;
  font-size: 1.2rem;
  line-height: 40px;
  animation: pulse 1.5s infinite;
}

/* Load More Button */
.btn-load-more {
  background: transparent;
  border: 2px solid #ff4e50;
  color: #ff4e50;
  padding: 10px 25px;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-load-more:hover {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  border-color: transparent;
  color: white;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 78, 80, 0.3);
}

/* Lightbox Styles */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.lightbox-img-container {
  max-height: 70vh;
  overflow: hidden;
}

.lightbox-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.lightbox-info {
  padding: 20px;
  background: white;
}

.lightbox-info h3 {
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
}

.lightbox-info p {
  color: #666;
  margin-bottom: 10px;
}

.lightbox-category .badge {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  color: white;
  font-size: 0.8rem;
  padding: 5px 10px;
  border-radius: 15px;
}

.lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: white;
  border: none;
  color: #333;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.lightbox-close:hover {
  transform: rotate(90deg);
  background: #ff4e50;
  color: white;
}

.lightbox-prev,
.lightbox-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: none;
  color: #333;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.lightbox-prev {
  left: 15px;
}

.lightbox-next {
  right: 15px;
}

.lightbox-prev:hover,
.lightbox-next:hover {
  background: linear-gradient(45deg, #ff4e50, #f9d423);
  color: white;
  transform: translateY(-50%) scale(1.1);
}

/* Animation Keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 78, 80, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 78, 80, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 78, 80, 0);
  }
}

/* Helper class for the body when lightbox is open */
:global(.no-scroll) {
  overflow: hidden;
}

/* Responsive Adjustments */
@media (max-width: 991px) {
  .gallery-section {
    padding: 80px 0;
  }
  
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  .gallery-item.large {
    grid-column: span 1;
  }
}

@media (max-width: 767px) {
  .gallery-section {
    padding: 60px 0;
  }
  
  .section-title {
    font-size: 1.8rem;
  }
  
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 15px;
  }
  
  .gallery-item.medium,
  .gallery-item.large {
    grid-row: span 1;
  }
}

@media (max-width: 575px) {
  .filter-btn {
    padding: 6px 15px;
    margin: 0 3px 8px;
    font-size: 0.9rem;
  }
  
  .gallery-grid {
    grid-gap: 10px;
  }
  
  .lightbox-content {
    max-width: 95%;
  }
  
  .lightbox-info {
    padding: 15px;
  }
  
  .lightbox-info h3 {
    font-size: 1.2rem;
  }
}
</style>